<template>
    
<div class="container  ">
    <div class="logo ">
      <img width="100" height="100" class="TextLogo" alt="" src="@/assets/images/logo.png" />
  <!-- <h3 class="TextLogo"> Doctor <span style="color:primary;">4</span> Me</h3> -->
     </div>
     <div class="text-cnter">
       <p class="P-Text mt-8 " >{{$t("DoctorBlockAlert")}}</p>
       <p class="P-Text mb-8" > {{$t("DoctorBlockAlertPlus")}}</p>

     </div>
     <vs-button style=" width:12%; ">{{$t("OK")}}  </vs-button>

</div>


</template>
<script>

import { domain } from "@/gloabelConstant.js";

export default {
     data() {
    return {
      baseURL: domain,
      Model: []
    };
  }, 
}
</script>
<style>

.vs-button{

background: #FFFFFF 0% 0% no-repeat padding-box  !important;
box-shadow: 0px 0px 9px !important;
border-radius: 6px  !important;
opacity: 1  !important;

}

.container
{

background: transparent radial-gradient(closest-side at 50% 50%, #004477 0%, #004477 100%) 0% 0% no-repeat padding-box;
box-shadow: -3px 0px 22px #00000029;
border-radius: 31px;
opacity: 1;
text-align: center;
 width: 70%;
padding-top: 2.5rem;
padding-bottom: 2.5rem;
}
.logo{


background: #FFFFFF 0% 0% no-repeat padding-box;
border-radius: 20px;
opacity: 1;
display: inline-block;

 width: 12REM; padding: .5rem;

}
.TextLogo{
  color: primary;
  letter-spacing: 0px;
  font-size: 2rem;
  font-weight:bold;
  

}
.P-Text{
 color: #FFFFFF;
 text-align: left !important    ;
font-size: 1.2rem;
 letter-spacing: 0px;
opacity: 1;
margin-left: 2rem;


}




</style>
